<div class="flex flex-col gap-3">
  <ng-content select="[questionLabel]"></ng-content>

    <ion-textarea [(ngModel)]="question.answer"
      mode="md"
      class="question-type-text lg:min-w-[300px]"
      placeholder="Ingresa tu respuesta en este campo"
      [autoGrow]="true"
      aria-label="Respuesta"
      [counter]="true" [maxlength]="customMaxlength"
      (keyup)="onKeyUp($event)"
      >
    </ion-textarea>
    <!-- value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tellus sem, auctor accumsan egestas sed, venenatis at ex. Nam consequat ex odio, suscipit rhoncus orci dictum eget. Aenean sit amet ligula varius felis facilisis lacinia nec volutpat nulla. Duis ullamcorper sit amet turpis sed blandit. Integer pretium massa eu faucibus interdum." -->
</div>
