import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
	MenuServiceProvider,
	UsuarioServiceProvider,
	FuncionesServiceProvider,
} from "src/app/service/index.providers";
import { AlertController, MenuController, ModalController, Platform } from "@ionic/angular";
import { Router, NavigationExtras } from "@angular/router";
// import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { SideMenuMobileComponent } from "../side-menu-mobile/side-menu-mobile.component";
import { NotificacionService } from "src/app/service/notificaciones-service/notificacion.service";
import { Observable, Subscription } from "rxjs";
import { ApiService } from "src/app/api.service";
import { ConexionServiceProvider, ConnectionStatus } from '../../service/conexion-service/conexion-service';
@Component({
	selector: "app-footer-tab",
	templateUrl: "./footer-tab.component.html",
	styleUrls: ["./footer-tab.component.scss"],
})
export class FooterTabComponent implements OnInit, OnDestroy {
	img_perfil = "assets/imgs/user.png";
	img_perfil_f = "assets/imgs/user-f.png";
	usuario: any;
	color_marca: any;
	text: string;
	@Input() public act_icon: string = "cursos";
	carrera: any;
	private showMe: boolean = false;
	select_page: string = "anuncios";
	iniciales: String;
	new_notification: boolean = false;
	array_menu4$: Observable<any[]>;
	array_menu4: any;
	data_empresa;
	isOptionSelected: boolean | null = null;
	sideBarIsOpen: boolean = false;
	@Input() platformIsIOS : boolean | null = null;

	networkIsActive: boolean = true;
	isCordovaApp: boolean = false;
	private networkObservable: Subscription;

	constructor(
		private fp: FuncionesServiceProvider,
		private up: UsuarioServiceProvider,
		private alertCtrl: AlertController,
		private menup: MenuServiceProvider,
		private router: Router,
		private menu: MenuController,
		// private iab: InAppBrowser,
		private modalCtrl: ModalController,
		private mp: MenuServiceProvider,
		private ns: NotificacionService,
		private api: ApiService,
		private menuCtrl: MenuController,
		private networkService: ConexionServiceProvider,
		private platform: Platform,
	) {
		this.up.data_usuario$.subscribe((data) => (this.usuario = data));
		this.menu.swipeGesture(true);
		this.array_menu4$ = this.fp.getPaginas_main();
		this.array_menu4$.subscribe((paginas) => (this.array_menu4 = paginas));
		if (!this.array_menu4) this.array_menu4 = this.fp._pages_main;

		this.up.configBrand.subscribe((res) => {
			this.data_empresa = res;
		});

		if (this.platform.is("cordova")) {
			this.isCordovaApp = true;
			this.checkNewtworkStatus();
		}

	}

	ngOnInit() {
		if (localStorage.getItem("select_page")) this.select_page = localStorage.getItem("select_page");
		this.mp.select_page.subscribe((page) => (this.select_page = page));
		this.ns.new_notification.subscribe((res) => (this.new_notification = res));
		this.usuario = JSON.parse(localStorage.getItem("user_data"));
		if (this.usuario) {
			// this.iniciales = this.data_usuario.nombre.split(" ").map((v) => v[0].charAt(0).toUpperCase());
			this.iniciales =
				this.usuario.nombre.charAt(0).toUpperCase() + this.usuario.apellido.charAt(0).toUpperCase();
		}
	}

	ionViewDidEnter() {
		this.usuario = JSON.parse(localStorage.getItem("user_data"));
		if (this.usuario) {
			// this.iniciales = this.data_usuario.nombre.split(" ").map((v) => v[0].charAt(0).toUpperCase());
			this.iniciales =
				this.usuario.nombre.charAt(0).toUpperCase() + this.usuario.apellido.charAt(0).toUpperCase();
		}
	}

	checkNewtworkStatus() {
		console.log("footerTab.ts check network status!!!!");
		this.networkObservable = this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
			if (status == ConnectionStatus.Online) {
				console.log("online footer.ts");
				this.networkIsActive = true;
				//   this.offlineManager.checkForEvents().subscribe();
			} else {
				console.log("offline footer.ts");
				this.networkIsActive = false;
			}
		});
	}

	openPage(pagina: string) {
		this.isOptionSelected = this.select_page === pagina ;
		this.showMe = false;
		this.menup.setShowMenu(this.showMe);
		this.mp.select_page.emit(pagina);
		this.mp.select_group.emit(null);
		switch (pagina) {
			case "anuncios":
				// if (!this.router.url.includes('anuncios')){
				this.fp.transitionsPush("up");
				this.router.navigate(["anuncios"]);
				// }
				break;
			case "cursos":
				// if (!this.router.url.includes('cursos')){
				this.fp.transitionsPush("up");
				this.router.navigate(["cursos"]);
				// }
				break;
			// case "cursosextra":
			//   this.router.navigate(['cursos', 'extra']);
			//   break;
			case "progreso":
				// if (!this.router.url.includes('progreso')){
				this.fp.transitionsPush("up");
				this.router.navigate(["progreso"]);
				// }
				break;
			case "lista-checklists":
				this.router.navigate(["lista-checklists"]);
				break;
			case "preguntas":
				this.router.navigate(["faq"]);
				break;
			case "glosario":
				this.router.navigate(["glosario"]);
				break;
			case "encuestas_libres":
				this.router.navigate(["encuesta-libre"]);
				break;
			case "ranking":
				this.router.navigate(["ranking"]);
				break;
			case "diplomas":
				this.router.navigate(["diploma"]);
				break;
			case "ayuda":
				this.router.navigate(["ayuda"]);
				break;
			case "temas_libres":
				this.router.navigate(["temas-libres"]);
				break;
			case "lista-reuniones":
				if (!this.router.url.includes("lista-reuniones")) {
					this.fp.transitionsPush("up");
					this.router.navigate(["lista-reuniones"]);
				}
				break;
			case "home":
				this.router.navigate(["home"]);
				break;
			case "map":
				let extras: NavigationExtras = {
					queryParams: {
						ruta: 'map',
					},
				};
				this.router.navigate(["home"], extras);
				break;
			case "faq":
				this.router.navigate(["faq"]);
				break;
			case "procesos":
				this.router.navigate(["procesos"]);
				break;
			case "lista-colaboradores":
				this.router.navigate(["lista-colaboradores"]);
				break;
			default:
				this.router.navigate(["cursos", "regular"]);
				break;
		}
	}

	async openSideBarMobile() {
		this.sideBarIsOpen = true;
		// const modal = await this.modalCtrl.create({
		// 	component: SideMenuMobileComponent,
		// 	cssClass: "side-menu-mobile",
		// });
		// await modal.present();
		this.menuCtrl.toggle();
	}

	navegarPagina(ruta: any) {
		this.showMe = false;
		this.menup.setShowMenu(this.showMe);
		this.fp.transitionsPush("up");
		this.router.navigate(ruta);
	}

	showMenu2() {
		// this.showMe = !this.showMe;
		// this.menup.setShowMenu(this.showMe);
		this.act_icon = "menu";
	}
	hideMenu2() {
		this.showMe = false;
		this.menup.setShowMenu(this.showMe);
	}

	async confirmarSalir() {
		let alert = await this.alertCtrl.create({
			header: "Cerrar Sesión",
			message: "¿Seguro que deseas salir de la aplicación?",
			mode: "ios",
			buttons: [
				{
					text: "Cancelar",
					role: "cancel",
				},
				{
					text: "Seguro",
					handler: () => {
						this.cerrarSesion();
					},
				},
			],
		});
		await alert.present();
	}

	async cerrarSesion() {
		await this.api.logout_token()
		.then((res) => {
			this.up.clearStorage();
			this.router.navigate(["login"]);
			})
		.catch((e) => {
			console.log(e.status, e.url);
		});
	}

	ngOnDestroy() {
		if(this.networkObservable) {
			this.networkObservable.unsubscribe();
		}
	}
}
