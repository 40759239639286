import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pregunta-opc-simple',
  templateUrl: './pregunta-opc-simple.component.html',
  styleUrls: ['./pregunta-opc-simple.component.scss'],
})
export class PreguntaOpcSimpleComponent  implements OnInit {
  @Input() question: any;
  @Input() questionOptions: any;
  @Input() optionsType: any;

  @Input() isRadioDisabled: boolean = false;

  constructor() { }

  ngOnInit() {}

}
