import { Component, Input, OnInit } from "@angular/core";
import { UsuarioServiceProvider } from "src/app/service/index.providers";

@Component({
	selector: "app-image-svg",
	templateUrl: "./image-svg.component.html",
	styleUrls: ["./image-svg.component.scss"],
})
export class ImageSvgComponent implements OnInit {
	@Input() name: string = "";
	@Input() customClass: string = ""; // image size 
	brandConfig;
	primaryColor: string | null = null;
	secondaryColor: string | null = null;
	tertiaryColor: string | null = null;
	primaryLightColor: string | null = null;

	constructor(private up: UsuarioServiceProvider) {
		this.up.configBrand.subscribe((res) => {
			//console.log("data empresa en IMAGEN SVG::", res);
			this.brandConfig = res;

			if (this.brandConfig) {
				this.primaryColor = this.brandConfig.color_primario_app;
				this.secondaryColor = this.brandConfig.color_secundario_app;
				this.tertiaryColor = this.brandConfig.color_terciario_app
					? this.brandConfig.color_terciario_app
					: this.brandConfig.color_secundario_app;
				//  this.primaryColor = "#fac60c";
				// this.secondaryColor = "#f53b11";
				// this.tertiaryColor = "#9a29e6";

				//red,green,blue(primary)
				let rgb;
				let result_pri;
				try {
					result_pri = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.primaryColor);
					rgb = {
						rp: parseInt(result_pri[1], 16),
						gp: parseInt(result_pri[2], 16),
						bp: parseInt(result_pri[3], 16),
					};
				} catch (error) {
					result_pri = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec("#5458e9");
					rgb = {
						rp: parseInt(result_pri[1], 16),
						gp: parseInt(result_pri[2], 16),
						bp: parseInt(result_pri[3], 16),
					};
				}
      			this.primaryLightColor = `rgb(${18*rgb.rp/10}, ${17.5*rgb.gp/10}, ${12.2*rgb.bp/10})`;
			}
		});
	}

	ngOnInit() {}
}
