<div class="confirmar-evaluacion mx-auto text-center bg-white my-auto rounded-xxl w-64 md:w-80 lg:w-96">
	<div
		class="py-4 mx-5 border-b-2 border-gray-200 font-bold text-base lg:text-xl text-gray-900 text-center"
	>
		Finalizar evaluación
	</div>
	<div class="p-8 text-center text-gray-600 lg:text-lg font-semibold">
		Para finalizar la evaluación haz clic en confirmar
	</div>
	<div class="lg:text-lg">
		<button (click)="cancelar()" class="w-1/2 py-4 bg-gray-200 font-bold rounded-bl-xxl">
			Cancelar
		</button>
		<button
			(click)="confirmar()"
			class="w-1/2 py-4 bg-secondary text-white font-bold rounded-br-xxl"
		>
			Confirmar
		</button>
	</div>
</div>
