<ion-app class="">
	<!-- <ion-router-outlet
    id="main"
  ></ion-router-outlet> -->
  <ng-container *ngIf="!router.url.includes('/auth/login/external') &&  showImpersonationBar && router.url != '/login' && router.url != '/login-loading'">
	<ion-header  mode="md">
		<ion-toolbar mode="ios" class="impersonation-toolbar">
			<ion-title>{{impersonationBarTitle}}</ion-title>
			<ion-buttons slot="end" class="mr-2">
				<ion-button (click)="logoutImpersonation()" >
					<span class="text-white font-medium">Salir</span>
					<ion-icon name="exit-outline" class="text-white font-bold ml-2"></ion-icon>
				  </ion-button>
			</ion-buttons>
		  </ion-toolbar>
		</ion-header>
  </ng-container>
  <app-side-menu-mobile [dataUsuarioProp]="userFullName" [initialsProp]="initials"></app-side-menu-mobile>
	<ion-router-outlet
		id="main"
		class="lg:mb-0"
		[ngClass]="{
			'mb-16':
				router.url != '/login' &&
				router.url != '/login-loading' &&
				!router.url.includes('recuperar-contrasenia') &&
				!router.url.includes('login-ayuda') &&
				!router.url.includes('cambiar-contrasenia') &&
				!router.url.includes('ayuda-coordinador') &&
				!router.url.includes('solicitud-enviada') &&
				!router.url.includes('validacion-usuario') &&
				!router.url.includes('validacion-quiz') &&
				!router.url.includes('error-validacion') &&
				!router.url.includes('evaluacion-curso') &&
				!router.url.includes('encuesta-preguntas') &&
				!router.url.includes('vademecum/scorm') &&
				!router.url.includes('slides-bienvenida') &&
				!router.url.includes('auth/login/external') &&
				!router.url.includes('register?c') &&
        !router.url.includes('validador-evaluacion') &&
        !router.url.includes('scorm-player') &&
        !router.url.includes('encuestas?') &&
        !router.url.includes('encuestas/') &&
				!fullscreen,
			'mr-0-i': router.url.includes('vista-conferencia'),
			'full': fullscreen,
			'mt-14': router.url != '/login' &&
					router.url != '/login-loading' &&
					router.url != '/auth/login/external' &&
					showImpersonationBar
		}"
	>
	<app-notification-modal *ngIf="showNotification"></app-notification-modal>
  </ion-router-outlet>

	<!-- <app-side-menu *ngIf="router.url != '/login' && router.url != '/login-loading' && router.url != '/vista-conferencia'"></app-side-menu> -->
	<app-footer-tab
		[platformIsIOS]="platformIsIOS"
		class="bottom-0 absolute  w-full"
		*ngIf="
			router.url != '/splash-screen' &&
			router.url != '/login' &&
			router.url != '/login-loading' &&
			!router.url.includes('recuperar-contrasenia') &&
			!router.url.includes('login-ayuda') &&
			!router.url.includes('cambiar-contrasenia') &&
			!router.url.includes('ayuda-coordinador') &&
			!router.url.includes('solicitud-enviada') &&
			!router.url.includes('validacion-usuario') &&
			!router.url.includes('validacion-quiz') &&
			!router.url.includes('error-validacion') &&
			!router.url.includes('evaluacion-curso') &&
			!router.url.includes('encuesta-preguntas') &&
			!router.url.includes('slides-bienvenida') &&
			!router.url.includes('vademecum/scorm') &&
			!router.url.includes('auth/login/external') &&
			!router.url.includes('register?c') &&
			!router.url.includes('validador-evaluacion') &&
			!router.url.includes('scorm-player') &&
      !router.url.includes('encuestas?') &&
      !router.url.includes('encuestas/') &&
			keyboardHidden && !fullscreen
		"
	></app-footer-tab>

</ion-app>


