import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-basic-modal',
  templateUrl: './basic-modal.component.html',
  styleUrls: ['./basic-modal.component.scss'],
})
export class BasicModalComponent implements OnInit {
  @Input()title: string | null = "";
  @Input()subtitle: string | null = "";
  @Input()buttonText: string | null = "";
  @Input()customStyle: string | null = null;
  @Input()customTitleSize: string | null = null;
  // modalMessages: string[] | null = null;
  @Input()projectedContent: TemplateRef<any>;
  @Input()notClose: boolean | null = false;

  constructor(private modalCtrl: ModalController, private navParams: NavParams,) { }

  ngOnInit() { }
  closeModal(okStatus) {
    //okStatus: true/false, define si se aceptó el mensaje del modal o se cerró el modal sin aceptar
    this.modalCtrl.dismiss({
      aceptar: okStatus
    });
  }

}
