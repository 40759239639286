<div
	class="basic-modal-container mx-auto text-center bg-white my-auto relative"
	[ngClass]=" customStyle ? customStyle : 'w-72 md:w-84'"
>
  <button *ngIf="!notClose"
		class="btn-close absolute z-2 -right-5 -top-5 lg:-right-3 lg:-top-3 flex justify-center items-center text-white rounded-full p-1 bg-primary_light hover:bg-primary_focused"
		(click)="closeModal(false)"
	> <ion-icon name="close" class="w-5 h-5 text-xl"></ion-icon>
	</button>

	<div *ngIf="title || subtitle" class="bg-primary header-modal py-3 px-2 lg:py-3.5 font-bold text-base leading-5 text-white text-center relative">
		<span [ngClass]=" customTitleSize ? customTitleSize : ''">{{ title }}</span> <br>
		<span *ngIf="subtitle">{{ subtitle }}</span>
	</div>

	<div class="flex flex-col px-4 py-4 text-gray-900 text-left text-base font-normal leading-5">
		<ng-container [ngTemplateOutlet]="projectedContent"></ng-container>

		<div *ngIf="buttonText" class="flex items-center justify-center">
			<ion-button class="" type="button" color="primary" (click)="closeModal(true)">
				<span> {{buttonText}} </span>
			</ion-button>
		</div>
	</div>
</div>
