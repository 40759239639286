import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-step-card',
  templateUrl: './step-card.component.html',
  styleUrls: ['./step-card.component.scss'],
})
export class StepCardComponent  implements OnInit {
  @Input() disabledCard: boolean = false;
  @Input() iconContaninerClass: string = "";
  @Input() iconName: string = "";
  @Input() haveCardChip: string | null = null;
  @Input() isLast: boolean = false;
  @Input() haveMiddleArrow: boolean = true;

  @Output() cardClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    console.log(this.iconContaninerClass);

   }

  onClickCard() {
    if(!this.disabledCard) this.cardClick.emit();
  }

}
