import { Injectable } from "@angular/core";
import { UsuarioServiceProvider } from "../usuario-service/usuario-service";
//! todo
// import { NativePageTransitions,	NativeTransitionOptions,} from "@ionic-native/native-page-transitions/ngx";
import { Observable, Subject } from "rxjs";
// export interface Paginas {
// 	name: string;
// 	path: string;
// 	icon?: string;
// }
@Injectable({ providedIn: "root" })
export class FuncionesServiceProvider {
	private ruta_imagen: string;
	public mod_evaluaciones: any = [];
	cargar_al_iniciar: number = 0;
	renovar_token: boolean = false;
	private timeCod: any;

	private pages: any[];
	private groupedPages: any[];
	finalMenu: any= [];
	private pages$ = new Subject<any[]>();

	private pages_main = [];
	private pages_main$ = new Subject<any[]>();

	constructor(
		public up: UsuarioServiceProvider,
		// private nativePageTransitions: NativePageTransitions
	) {}

	public transitionsPush(direccion) {
    //! todo
		// let options: NativeTransitionOptions = {
		// 	direction: direccion,
		// 	duration: 800,
		// 	slowdownfactor: 3,
		// 	slidePixels: 20,
		// 	iosdelay: 100,
		// 	androiddelay: 100,
		// };
		// this.nativePageTransitions.slide(null);
	}

	public get rutaImagen(): string {
		return this.ruta_imagen;
	}
	public set rutaImagen(v: string) {
		this.ruta_imagen = v;
	}

	public get mod_eval(): any {
		return this.mod_evaluaciones;
	}

	public get timeC(): any {
		return this.timeCod;
	}

	public get _pages(): any {
		return this.finalMenu;
	}

	getPaginas(): Observable<any[]> {
		return this.pages$.asObservable();
	}

	public get _pages_main(): any {
		return this.pages_main;
	}

	getPaginas_main(): Observable<any[]> {
		return this.pages_main$.asObservable();
	}

	async cargarFunciones() {
		this.timeCod = 2000;

		this.up.load_storage();

		let config_data = JSON.parse(this.up.getConfigData);
		this.pages = [];
		this.pages_main = [];

		let data_usuario = JSON.parse(localStorage.getItem("user_data"));
		let sidemenu;
		if(data_usuario.user_induccion_aprendizaje && data_usuario.onboarding)  sidemenu = this.up.getPlatformType === 'induction' ? config_data.full_app_side_menu_induccion : config_data.full_app_side_menu;
		else sidemenu = config_data.full_app_side_menu;

		// if (sidemenu.perfil) {
		// 	this.pages.push({ name: "Anuncios", path: "anuncios", icon: "assets/icon/anuncios.svg" });
		// }
		if (sidemenu.cursos) {
			this.pages.push({
				name: "Cursos",
				path: "cursos",
				icon: "assets/icon/book-open-variant.svg",
				group: "courses",
			});
		}
		if (sidemenu.cursosextra) {
			this.pages.push({
				name: "Extracurriculares",
				path: "extracurricular",
				icon: "assets/icon/book-account.svg",
				group: "courses"
			});
		}
		if (sidemenu.cursoslibres) {
			this.pages.push({
				name: "Libres",
				path: "libres",
				icon: "assets/icon/book-heart.svg",
				group: "courses"
			});
		}
		// if (sidemenu.progreso) {
		// 	this.pages.push({
		// 		name: "Progreso",
		// 		path: "progreso",
		// 		icon: "assets/icon/progreso.svg",
		// 		group: "",
		// 	});
		// }
		if (sidemenu.videoteca) {
			this.pages.push({
				name: "Videoteca",
				path: "videoteca",
				icon: "assets/icon/file-video.svg",
				group: "content",
			});
		}
		if (sidemenu.glosario) {
			this.pages.push({
				name: "Glosario",
				path: "glosario",
				icon: "assets/icon/alphabetical-variant.svg",
				group: "content",
			});
		}
		if (sidemenu.vademecun) {
			if (JSON.parse(localStorage.getItem("user_data")).workspace.slug === "farmacias-peruanas") {
				this.pages.push({
					name: "Vademécum",
					path: "vademecum",
					icon: "assets/icon/book-education.svg",
					group: "content",
				});
			} else {
				this.pages.push({
					name: "Documentos y protocolos",
					path: "documentos-y-protocolos",
					icon: "assets/icon/book-education.svg",
					group: "content",
				});
			}
		}

		if (sidemenu.beneficios) {
			this.pages.push({
				name: "Mis beneficios",
				path: "beneficios",
				icon: "assets/icon/gift-open.svg",
				group: "benefits",
			});
		}
		if (sidemenu.progreso) {
			this.pages.push({
				name: "Progreso",
				path: "progreso",
				icon: "assets/icon/chart-areaspline-variant.svg",
				group: "progress",
			});
		}
		if (sidemenu.diplomas) {
			this.pages.push({
				name: "Certificados",
				path: "certificados",
				icon: "assets/icon/certificate.svg",
				group: "progress",
			});
		}
		if (sidemenu.ranking) {
			this.pages.push({
				name: "Ranking",
				path: "ranking",
				icon: "assets/icon/podium-gold.svg",
				group: "progress",
			});
		}
		if (sidemenu.historico) {
			this.pages.push({
				name: "Historial",
				path: "historial-de-cursos",
				icon: "assets/icon/clock-star-four-points.svg",
				group: "progress",
			});
		}

		/* if (sidemenu.temas_libres) {
			this.pages.push({ name: "Temas Libres", path: "temas_libres" });
		} */
		if (sidemenu.tareas) {
			this.pages.push({
				name: "Tareas",
				path: "tareas",
				icon: "assets/icon/book-arrow-down.svg",
				group: "activities",
			});
		}
		if (sidemenu.aulas_virtuales) {
			this.pages.push({
				name: "Sesiones live",
				path: "lista-reuniones",
				icon: "assets/icon/video.svg",
				group: "activities",
			});
		}
		if (sidemenu.encuestas_libres) {
			this.pages.push({
				name: "Encuestas",
				path: "encuesta-libre",
				icon: "assets/icon/text-box-check.svg",
				group: "activities",
			});
		}
		if (sidemenu.subir_link) {
			this.pages.push({
				name: "Adjuntar archivo",
				path: "adjuntar-archivo",
				icon: "assets/icon/clipboard-arrow-up.svg",
				group: "activities",
			});
		}

		if (sidemenu.checklist) {
			this.pages.push({
				name: "Checklist",
				path: "lista-checklists",
				icon: "assets/icon/checkbox-multiple-marked.svg",
				group: "supervision",
			});
		}

		// if (sidemenu.checklist && JSON.parse(localStorage.getItem("user_data")).rol_entrenamiento) {
		// 	if (JSON.parse(localStorage.getItem("user_data")).rol_entrenamiento == "trainer") {
		// 		this.pages.push({
		// 			name: "Mi equipo",
		// 			path: "lista-alumnos",
		// 			icon: "assets/icon/account-group.svg",
		// 			group: "supervision",
		// 		});
		// 	} else {
		// 		this.pages.push({
		// 			name: "Checklist",
		// 			path: "alumno",
		// 			icon: "assets/icon/checkbox-multiple-marked.svg",
		// 			group: "supervision",
		// 		});
		// 	}
		// }

		if (sidemenu.reportes) {
			if (JSON.parse(localStorage.getItem("user_data")).supervisor)
				this.pages.push({
					name: "Reportes",
					path: "reportes",
					icon: "assets/icon/account-badge.svg",
					group: "supervision",
				});
		}

		if (sidemenu.reconocimiento) {
			this.pages.push({
				name: "Reconocimiento",
				path: "modulo-reconocimiento",
				icon: "assets/icon/trophy.svg",
				group: "recognition",
			});
		}
		if (sidemenu.preguntas) {
			this.pages.push({
				name: "Preguntas frecuentes",
				path: "faq",
				icon: "assets/icon/frequently-asked-questions.svg",
				group: "help",
			});
		}
		if (sidemenu.ayuda) {
			this.pages.push({
				name: "Ayuda",
				path: "ayuda",
				icon: "assets/icon/handshake.svg",
				group: "help",
			});
		}
		// Inducción
		if (sidemenu.ind_avance) {
			this.pages.push({
				name: "Avance",
				path: "home",
				icon: "assets/icon/ind_avance.svg",
				group: "",
			});
		}
		if (sidemenu.ind_ruta) {
			this.pages.push({
				name: "Ruta",
				path: "home",
				icon: "assets/icon/ind_ruta.svg",
				group: "",
			});
		}
		// if (sidemenu.ind_certificado) {
		// 	this.pages.push({
		// 		name: "Certificado",
		// 		path: "certificados",
		// 		icon: "assets/icon/ind_certificado.svg",
		// 		group: "",
		// 	});
		// }
		if (sidemenu.ind_asistencia) {
			this.pages.push({
				name: "Inasistencia",
				path: "lista-colaboradores",
				icon: "assets/icon/ind_inasistencia.svg",
				group: "",
			});
		}
		if (sidemenu.ind_procesos) {
			this.pages.push({
				name: "Procesos",
				path: "procesos",
				icon: "assets/icon/ind_procesos.svg",
				group: "",
			});
		}
		if (sidemenu.ind_faq) {
			this.pages.push({
				name: "FAQ",
				path: "faq",
				icon: "assets/icon/ind_faq.svg",
				group: "",
			});
		}

    // Si es lider (mentor) en inducción
    if (sidemenu.is_mentor_onboarding) {
      this.pages.push({
        name: "Solicitudes",
        path: "reuniones-pasantia",
        icon: "assets/icon/ind_induccion.svg",
        group: "",
      });
    }

		// Inducción y Aprendizaje
		if (sidemenu.ind_aprendizaje) {
			this.pages.push({
				name: "Regresar a capacitación",
				path: "aprendizaje",
				icon: "assets/icon/ind_capacitacion.svg",
				group: "",
			});
		}

		if (sidemenu.ind_induccion) {
			this.pages.push({
				name: "Supervisar inducción",
				path: "induccion",
				icon: "assets/icon/ind_induccion.svg",
				group: "",
			});
		}

		this.groupedPages = [
			{
				groupName: "Mis cursos",
				groupIcon: "assets/icon/book-open-page-variant.svg",
				groupedItems : [],
				isGrouped: true
			},
			{
				groupName: "Mi avance",
				groupIcon: "assets/icon/chart-timeline-variant.svg",
				groupedItems : [],
				isGrouped: true
			},

			{
				groupName: "Mis actividades",
				groupIcon: "assets/icon/clipboard-account.svg",
				groupedItems : [],
				isGrouped: true
			},
			{
				groupName: "Mis beneficios",
				groupIcon: "assets/icon/gift-open.svg",
				groupedItems : [],
				isGrouped: false
			},
			{
				groupName: "Supervisión",
				groupIcon: "assets/icon/book-edit.svg",
				groupedItems : [],
				isGrouped: true
			},

			{
				groupName: "Reconocimiento",
				groupIcon: "assets/icon/trophy.svg",
				groupedItems : [],
				isGrouped: false
			},
			{
				groupName: "Más recursos",
				groupIcon: "assets/icon/multimedia.svg",
				groupedItems : [],
				isGrouped: true
			},
			{
				groupName: "Centro de ayuda",
				groupIcon: "assets/icon/help-circle.svg",
				groupedItems : [],
				isGrouped: true
			}

		];
		for(let page of this.pages ) {
			if(page.group === "courses"){
				this.groupedPages[0].groupedItems.push(page)
			}
			else if(page.group === "progress"){
				this.groupedPages[1].groupedItems.push(page)
			}

			else if(page.group === "activities"){
				this.groupedPages[2].groupedItems.push(page)
			}
			else if(page.group === "benefits"){
				this.groupedPages[3].groupedItems.push(page)
			}
			else if(page.group === "supervision"){
				this.groupedPages[4].groupedItems.push(page)
			}

			else if(page.group === "recognition"){
				this.groupedPages[5].groupedItems.push(page)
			}
			else if(page.group === "content"){
				//más recursos
				this.groupedPages[6].groupedItems.push(page)
			}
			else if(page.group === "help"){
				this.groupedPages[7].groupedItems.push(page)
			}
			else {
				this.groupedPages.push({'groupedItems': [page]})
			}
		}

		for( let group of this.groupedPages ) {
			if(group.groupedItems.length === 1){
				group.groupName = group.groupedItems[0].name;
				group.groupIcon = group.groupedItems[0].icon;
				group.isGrouped = false;
			}

		}
		this.finalMenu = [];
		for( let group of this.groupedPages ) {
			if(group.groupedItems.length > 0){
				this.finalMenu.push(group);
			}
		}
		// console.log("groupedMenu::",this.finalMenu);


		this.pages$.next(this.finalMenu);
		// this.pages$.next(this.pages);

		let main_menu
		if(data_usuario.user_induccion_aprendizaje && data_usuario.onboarding) main_menu = this.up.getPlatformType === 'induction' ? config_data.full_app_main_menu_induccion : config_data.full_app_main_menu;
		else main_menu = config_data.full_app_main_menu;

		if (main_menu.anuncios) {
			this.pages_main.push({
				name: "Anuncios",
				path: "anuncios",
				icon: "assets/icon/bullhorn-outline.svg",
				selectedIcon: "assets/icon/bullhorn.svg",
			});
		}
		if (main_menu.cursos) {
			this.pages_main.push({
				name: "Cursos",
				path: "cursos",
				icon: "assets/icon/book-open-page-variant-outline.svg",
				selectedIcon: "assets/icon/book-open-page-variant.svg",
			});
		}
		if (main_menu.aulas_virtuales) {
			this.pages_main.push({
				name: "Sesiones",
				path: "lista-reuniones",
				icon: "assets/icon/video-outline.svg",
				selectedIcon: "assets/icon/video.svg",
			});
		}
		if (main_menu.progreso) {
			this.pages_main.push({
				name: "Progreso",
				path: "progreso",
				icon: "assets/icon/chart-line.svg",
				selectedIcon: "assets/icon/chart-areaspline-variant.svg",
			});
		}
		if(main_menu.ind_home_sup) {
			this.pages_main.push({
				name: "Inicio",
				path: "procesos",
				icon: "assets/icon/ind-home.svg",
				selectedIcon: "assets/icon/ind_nav_home_selected.svg",
			});
		}
		if(main_menu.ind_procesos) {
			this.pages_main.push({
				name: "Inasistencia",
				path: "lista-colaboradores",
				icon: "assets/icon/ind_nav_inasistencia.svg",
				selectedIcon: "assets/icon/ind_nav_inasistencia_selected.svg",
			});
		}
		if(main_menu.ind_asistencia) {
			this.pages_main.push({
				name: "Procesos",
				path: "procesos",
				icon: "assets/icon/ind_nav_procesos.svg",
				selectedIcon: "assets/icon/ind_nav_procesos_selected.svg",
			});
		}
		if(main_menu.ind_home) {
			this.pages_main.push({
				name: "Inicio",
				path: "home",
				icon: "assets/icon/ind-home.svg",
				selectedIcon: "assets/icon/ind_nav_home_selected.svg",
			});
		}
		if(main_menu.ind_ruta) {
			this.pages_main.push({
				name: "Ruta",
				path: "map",
				icon: "assets/icon/ind_nav_ruta.svg",
				selectedIcon: "assets/icon/ind_nav_ruta_selected.svg",
				hideInWeb: true
			});
		}
		if(main_menu.ind_faq) {
			this.pages_main.push({
				name: "FAQ",
				path: "faq",
				icon: "assets/icon/ind-faq.svg",
				selectedIcon: "assets/icon/ind_faq.svg",
			});
		}
		if(main_menu.ind_aprendizaje) {
			this.pages_main.push({
				name: "Regresar a capacitación",
				path: "aprendizaje",
				icon: "assets/icon/ind_capacitacion.svg",
				selectedIcon: "assets/icon/ind_capacitacion.svg",
			});
		}
		if(main_menu.ind_induccion) {
			this.pages_main.push({
				name: "Supervisar inducción",
				path: "induccion",
				icon: "assets/icon/ind_induccion.svg",
				selectedIcon: "assets/icon/ind_induccion.svg",
			});
		}
    if (main_menu.checklist) {
			this.pages_main.push({
				name: "Checklist",
				path: "lista-checklists",
				icon: "assets/icon/checkbox-multiple-marked-outline.svg",
				selectedIcon: "assets/icon/checkbox-multiple-marked.svg",
			});
		}
//icon: "assets/icon/account-group.svg",
		this.pages_main$.next(this.pages_main);
	}
}
