import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent  implements OnInit {
  @Input() title: string = '';
  @Input() displayMobileBackIcon:boolean = false;
  @Input() displayWebBackIcon:boolean = false;
  @Output() backClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  onBackClick() {
    this.backClick.emit();
  }

}
