import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FuncionesServiceProvider, MenuServiceProvider, UsuarioServiceProvider } from "src/app/service/index.providers";
import { AlertController, MenuController, NavController, Platform } from "@ionic/angular";
import { NavigationExtras, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiService } from "src/app/api.service";
import { ConexionServiceProvider, ConnectionStatus } from '../../service/conexion-service/conexion-service';

@Component({
	selector: "app-side-menu",
	templateUrl: "./side-menu.component.html",
	styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit, OnDestroy {
	@Input() navControl: any;
	array_menu2$: Observable<any[]>;
	array_menu2: any;
	swipe: boolean = false;
	categorias: any;
	url;
	data_usuario;
	page_select: String = "anuncios";
	SideBarActive: boolean = false;
	displayAccordionSection: number | null = null;
	groupSelected:number | null = null;
	subGroupSelected:number | null = null;
	showMenuLateral:boolean = true;
	networkIsActive: boolean = true;
	isCordovaApp: boolean = false;
	private networkObservable: Subscription;

	constructor(
		private fp: FuncionesServiceProvider,
		private alertCtrl: AlertController,
		private menu: MenuController,
		public router: Router,
		private navCtrl: NavController,
		private mp: MenuServiceProvider,
		private api: ApiService,
		private up: UsuarioServiceProvider,
		private platform: Platform,
		private networkService: ConexionServiceProvider,

	) {
		if (this.platform.is("cordova")) {
			this.isCordovaApp = true;
			this.checkNewtworkStatus();
		}
	}

	ngOnInit() {
		//obtniene el array de paginas
		this.array_menu2$ = this.fp.getPaginas();
		this.array_menu2$.subscribe((paginas) => {
			this.array_menu2 = paginas;
		});
		if (!this.array_menu2) {
			this.array_menu2 = this.fp._pages;
		}
		if (localStorage.getItem("select_page")) this.page_select = localStorage.getItem("select_page");
		if (localStorage.getItem("select_group")) this.groupSelected = parseInt(localStorage.getItem("select_group"));
		if (localStorage.getItem("select_sub_group")) this.subGroupSelected = parseInt(localStorage.getItem("select_sub_group"));
		this.mp._showMenu.subscribe((state) => (this.SideBarActive = state));
		this.mp.select_page.subscribe((page) => (this.page_select = page));
		this.mp.select_group.subscribe((index) => (this.groupSelected = index));
		this.mp.select_sub_group.subscribe((index) => (this.subGroupSelected = index));
		// if(this.up.getPlatformType === 'induction')	this.showMenuLateral = false;
	}

	checkNewtworkStatus() {
		console.log("sidemenu.ts check network status!!!!");
		this.networkObservable = this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
			if (status == ConnectionStatus.Online) {
				console.log("online sidemenu.ts");
				this.networkIsActive = true;
			} else {
				console.log("offline sidemenu.ts");
				this.networkIsActive = false;
			}
		});
	}

	/* Routes = [
    { name: 'Anuncios', path: 'anuncios', icon: 'assets/icon/anuncios.svg' },
    { name: 'Cursos', path: 'cursos', icon: 'assets/icon/cursos.svg' },
    { name: 'Reuniones', path: 'lista-reuniones', icon: 'assets/icon/reunion.svg' },
    { name: 'Progreso', path: 'progreso', icon: 'assets/icon/progreso.svg' },
    { name: 'Cursos Extracurriculas', path: 'cursos-extra', icon: 'assets/icon/cursos_extra.svg' },
    { name: 'Glosario', path: 'glosario', icon: 'assets/icon/glosario.svg' },
    { name: 'Adjuntar Archivo', path: 'adjuntar-archivo', icon: 'assets/icon/clip.svg' },
    { name: 'Ranking', path: 'ranking', icon: 'assets/icon/ranking.svg' },
    { name: 'Diploma', path: 'diploma', icon: 'assets/icon/diploma.svg' },
    { name: 'Ayuda', path: 'ayuda', icon: 'assets/icon/ayuda.svg' },
    { name: 'Preguntas Frecuentes', path: 'faq', icon: 'assets/icon/faq.svg' },
  ] */
	to(routeLink, groupIndex = null, subGroupIndex = null) {
		groupIndex ? this.groupSelected = groupIndex : this.groupSelected = this.displayAccordionSection;
		this.subGroupSelected = subGroupIndex;

		this.SideBarActive = false;
		this.mp._showMenu.emit(this.SideBarActive);
    console.log("to routeLink:",routeLink);


			if(routeLink === "induccion"){
				let data_usuario = JSON.parse(localStorage.getItem("user_data"));
				routeLink = data_usuario.supervisor_induccion ? "procesos" : 'home';
				localStorage.setItem("platform_type", 'induction');
				this.fp.cargarFunciones();
			}
			if(routeLink === "aprendizaje"){
				routeLink = "anuncios";
				localStorage.setItem("platform_type", 'capacitacion');
				this.fp.cargarFunciones();
			}

			this.mp.select_page.emit(routeLink);
			this.mp.select_group.emit(this.groupSelected);
			this.mp.select_sub_group.emit(this.subGroupSelected);
			if (routeLink == "extracurricular" || routeLink == "libres") {
				let extras: NavigationExtras = {
					queryParams: {
						categoria: routeLink,
					},
				};
				this.router.navigate(["progreso"], extras);
			} else {
				this.router.navigate([routeLink]);
			}
	}

	accordeonActive(index) {
		this.SideBarActive = true;
		this.mp._showMenu.emit(this.SideBarActive);
		if(index === this.displayAccordionSection){
			this.displayAccordionSection = null;
		} else{
			this.displayAccordionSection = index;
		}
	}

	async confirmarSalir() {
		this.SideBarActive = false;
		this.mp._showMenu.emit(this.SideBarActive);
		let alert = await this.alertCtrl.create({
			header: "Cerrar sesión",
			message: "¿Seguro que deseas salir de la aplicación?",
			mode: "ios",
			buttons: [
				{
					text: "Cancelar",
					role: "cancel",
				},
				{
					text: "Seguro",
					handler: () => {
						this.cerrarSesion();
					},
				},
			],
		});
		await alert.present();
	}

	async cerrarSesion() {
		await this.api.logout_token()
		.then((res) => {
			this.up.clearStorage();
			this.menu.swipeGesture(false);
			// this.navCtrl.navigateRoot("login");
			this.router.navigate(["login"]);
			})
		.catch((e) => {
			console.log(e.status, e.url);
		});
	}

	ngOnDestroy() {
		if(this.networkObservable) {
			this.networkObservable.unsubscribe();
		}
	}
}
