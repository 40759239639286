<div class="title-grid-comp division-line border-b-1 border-solid title-padding">
  <div class="title flex flex-row items-center">
    <button *ngIf="displayMobileBackIcon || displayWebBackIcon" class="flex justify-center items-center bg-transparent border-none h-fit">
      <ion-icon *ngIf="displayMobileBackIcon" name="arrow-back" (click)="onBackClick()" class="text-xl mr-3 leading-5 lg:hidden"></ion-icon>
      <mat-icon *ngIf="displayWebBackIcon"  (click)="onBackClick()" class="mdi mdi-chevron-left text-xl lg:text-[26px] mr-3 leading-5 lg:leading-[26px] cursor-pointer hidden lg:block font-bold"></mat-icon>
    </button>
    <!-- <ion-icon *ngIf="alwaysDisplayBackIcon" name="arrow-back" (click)="onBackClick()" class="text-xl lg:text-2xl mr-3 leading-5 lg:leading-6 cursor-pointer"></ion-icon> -->

    <!-- el icono debería tener la clase title-icon -->
    <ng-content select="[pageTitleIcon]"></ng-content>
    <!-- <mat-icon class="mdi mdi-school  title-icon"></mat-icon> -->
    <span class="">{{title}} </span>
  </div>
  <div class="flex flex-row justify-end">
    <!-- Buscar -->
    <ng-content select="[titleExtraComponent]"></ng-content>
  </div>
</div>
